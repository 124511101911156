<template>
    <div class="body">
        <div class="concol" v-if="content.value">
          <div class="title" v-if="content.value.en_title">{{content.value.en_title}}</div>
          <div class="content" v-html="content.value.en_content">
          </div>
        </div>
    </div>
</template>
<script>
  import { defineComponent, ref, computed, watch, reactive } from "vue";
  import { useGetters } from "@/store/use.js";
  import { useRouter, onBeforeRouteUpdate } from "vue-router";
  import store from "@/store";
  import { useI18n } from "vue-i18n";
  import nodata from "@/pages/nodata.vue";
import { ElMessageBox, ElMessage } from 'element-plus'
  export default defineComponent({
    name: "评审规则",
    components: {
      nodata,
    },
    setup() {
      const { t, locale } = useI18n();
      const router = useRouter();
      const getters = useGetters(["language",]);
      const language = getters.language;
       const content=reactive({})
      store.dispatch('app/getJudges_rules').then(res=>{
        content.value=res.selection_rule;
      });
      return {
        language,
        content
      };
    },
  });
</script>
<style lang="scss" scoped>
    .concol{
      background: #fff;
      color: #333333;
      margin-bottom:50px;
      margin-top: 80px;
      .content {
        line-height: 28px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .title {
      font-size: 30px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 50px;
      line-height: 42px;
      // margin-top: 80px;
    }
  @media screen and (min-width: 375px) and (max-width: 750px) {
    .contentbody {
      width: 95%;
      .menucol{display: none;}
      .concol{max-width: 100%;flex:100%}
    }
  }
</style>