<template>
  <div class="body" style="margin-top: 60px;background: #fff;">
    <div>
      <el-form :inline="true" :model="saerchForm" class="demo-form-inline">
        <el-form-item label="Submission status:">
          <el-select v-model="saerchForm.submit_state"  style="width:185px">
            <el-option label="All" value="" />
            <el-option label="Submitted" value="2" />
            <el-option label="Not submitted" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="Name of the applicant:" class="form-label">
          <el-input v-model="saerchForm.en_name" style="width:250px" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList" style="margin-right:20px">Search</el-button>
          <el-button type="primary" @click="Submitbybatch" value='批量提交 '>Submit by batch</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table class="table" :data="tableData" border style="width: 100%" v-loading='loading' @selection-change='selectionChange' empty-text='No data'>
        <el-table-column type="selection" width="45" align='center' :selectable="selectable"  />
        <el-table-column type="index" width="80" label="Number" align='center'/>
        <el-table-column prop="en_name" label="Name of the applicant" align='center' width="180">
          <template #default='scope'>{{scope.row.en_name||scope.row.cn_name}}</template>
        </el-table-column>
        <el-table-column prop="score" label="Score" align='center' width="60"/>
        <el-table-column prop="comment" label="Comments" align='center' width="200">
          <template #default='scope'>
            <div class="comment" :title="scope.row.comment">{{scope.row.comment}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="提交状态" label="Submission status" align='center' width="145">
          <template #default='scope'>
            {{scope.row.submit_state===1?'Not submitted':'Submitted '}}
          </template>
        </el-table-column>
        <el-table-column prop="submit_time" label="Submission time" align='center' width='140'>
           <template #default='scope'>
            {{scope.row.submit_time&&scope.row.submit_time.split(' ')[0]}}
          </template>
        </el-table-column>
        <el-table-column prop="" label="Actions" fixed="right" align='center' width='265'>
          <template #default="scope">
            <el-link v-if="scope.row.confirm_state==1&scope.row.submit_state==1" type="text" size="small" @click="confirm(scope.row)" style="margin-right: 16px;" value='评审确认'>Check the basic information</el-link>
            <el-link v-if="scope.row.confirm_state==2&scope.row.submit_state==1&scope.row.review_state==2" type="text" size="small" style="margin-right: 16px;" value='去评审' @click="toReview_edit(scope.row)">Start reviewing</el-link>
            <el-link v-if="scope.row.confirm_state==2&scope.row.submit_state==2" type="text" size="small" style="margin-right: 16px;" value='查看详情'  @click="toReview_edit(scope.row,'details')">View details</el-link>
            <template v-if="scope.row.confirm_state==2&scope.row.submit_state==1&scope.row.review_state==1">
               <el-link type="text" size="small" style="margin-right: 16px;" value='修改' @click="toReview_edit(scope.row)">Edit</el-link>
              <el-link type="text" size="small" @click="Submit(scope.row)" value='提交'>Submit</el-link>
            </template>
           
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;margin-top: 40px;display: flex;justify-content: center;" v-if="tableData.length>0" >
       <!-- <div style="line-height:38px;color:#666;font-size:14px;"> 
         <span style="margin-right:15px">{{total}} item in total</span>
         <span>每页 20 条</span>
        </div> -->
        <el-pagination prev-text='Previous' next-text='Next' background  
        layout="prev, pager, next" 
        :page-size="page_size"
        :total="total"
        @current-change="handleCurrentChange"/>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent, ref, computed, watch, reactive } from "vue";
  import { useGetters } from "@/store/use.js";
  import { ElMessageBox, ElMessage } from 'element-plus'
  import { useRouter, onBeforeRouteUpdate } from "vue-router";
  import store from "@/store";
  import { useI18n } from 'vue-i18n'
  import { Ajax } from "@/utils";
  export default defineComponent({
    name: "评审信息",
    components: {
    },
    setup() {
      const { t, locale } = useI18n()
      const router = useRouter();
      const getters = useGetters(["language"]);
      const language = getters.language;
      const tableData = ref([]),page=ref(1),sel_table=ref([]),loading=ref(false),total=ref(0);
      const saerchForm=reactive({
        submit_state:'',//1 为提交 2 已提交
        en_name:'',//候选人姓名
        page:1,
        page_size:10
      })
      //获取列表数据
      const getList=()=>{
        loading.value=true
          Ajax.POST({url: "/api/judges/reviewList",data:saerchForm}).then((res) => {
          if (res.data.code === 10000) {
            loading.value=false
            tableData.value=res.data.data.items;
            total.value=res.data.data.total
          }
        });
      }
      const selectable=(row,index)=>{
        if(row.submit_state===2){
          return false
        }else{
          return true
        }
      }
      getList()
      const handleCurrentChange=(v)=>{//分页选择事件
        saerchForm.page=v
        getList()
      }
      const selectionChange=(v)=>{//复选框触发事件
          sel_table.value=v
      }
      const confirm=(row)=>{//确认评审事件
            router.push('/confirm?apply_id='+row.apply_id+'&id='+row.id)
      }
      const Submitbybatch=()=>{//批量提交
        if(sel_table.value.length<1){
          ElMessage({
                    message: 'Please select the items you would like to submit.',
                    type: 'error',
                })
        }else{
          let list = sel_table.value.filter(v =>( v.confirm_state===1||v.confirm_state===2)&v.review_state===2)
          let id=ref([])
          if(list.length>0){
             ElMessage({
                    message: 'There are unfinished reviewing items. Please confirm before submitting.',
                    type: 'error',
                })
          }else{
            sel_table.value.forEach(v => {
              if(v.submit_state===1){
                id.value.push(v.id)
              }
            });
            if(id.value.length>0)
              submit_response({ids:id.value.join(',')});
            else{
              ElMessage({
                    message: 'The submitted data cannot be submitted again.',
                    type: 'error',
                })
            }
          }
        }
      }
      const Submit=(row)=>{//提交事件
        submit_response({ids:row.id});
      }
      const toReview_edit=(row,v)=>{//去评审，编辑事件
      if(v){
         window.open('/details?flag=true&apply_id='+row.apply_id+'&id='+row.id)
      }else{
         router.push('/details?apply_id='+row.apply_id+'&id='+row.id)
      }
        // let url=v?'/details?flag=true&apply_id='+row.apply_id+'&id='+row.id:'/details?apply_id='+row.apply_id+'&id='+row.id
        //  router.push(url)
      }
     const submit_response=(form)=>{
       ElMessageBox.confirm('Your review cannot be changed after submission. Please confirm your submission.', 'Tips', {
            confirmButtonText:'Confirm',
            cancelButtonText:'Cancel',
            type: 'warning'
          }).then(() => {
             Ajax.POST({url: "/api/judges/submit",data:form}).then((res) => {
              if (res.data.code === 10000) {
                ElMessage({
                    message: 'Submitted successfully',
                    type: 'success',
                })
                setTimeout(()=>{
                  getList()
                },1000)
              }
            });
            }).catch(() => { return false})
      }
      return {
        t,
        language,
        loading,
        tableData,
        total,
        page,
        saerchForm,
        getList,
        handleCurrentChange,
        selectionChange,
        Submitbybatch,
        Submit,
        confirm,
        toReview_edit,
        selectable
      };
    },
  });
</script>

<style lang="scss" scoped>
  .body {
    :deep(.demo-form-inline){
      .el-form-item{margin-right: 0px;margin-bottom: 30px;}
      .form-label{margin-left: 60px;margin-right: 63px;}
    }
    :deep(.table) {
      thead tr th {
        background: #F0F0F0;
        opacity: 0.9;
        border: 1px solid #F5F5F5;
        height: 62px;
        padding: 0px;

        .cell {
          color: #333;
          /* font-size: 18px; */
          font-weight: 400;
        }
      }

      tbody .el-table__cell {
        padding: 0px;
        height: 56px;

        .el-link {
          font-size: 16px;
          font-weight: 400;
          color: #E34C3E;
          line-height: 16px;
        }
        .comment{
          width: 100%;
          height: 55px;
          line-height: 55px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }

    :deep(.el-pagination) {
      background-color: #fff;

      button,.el-pager li {
        width: 34px;
        height: 34px;
        line-height: 32px;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #DCDCDC;
      }
      button{width: 70px !important;span{line-height: 34px;}}
    }
  }
</style>