<template>
    <div class="body">
        <div class="contentbody">
            <div class="maintitle">
                <div v-for="item in tabList" :key="item.ind" :class="item.ind === activeInd && 'active'"
                    @click="() => tabClick(item.ind)">{{ item.name }}</div>
            </div>
            <JudgingGuidelines v-if="activeInd === 1"/>
    <ReviewingInformation v-else/>
        </div>
    </div>
</template>
<script>
    import ReviewingInformation from './components/ReviewingInformation.vue';
    import JudgingGuidelines from './components/JudgingGuidelines.vue';
    import { defineComponent, ref, reactive } from 'vue'
    import { useStore } from 'vuex'
    import { useI18n } from 'vue-i18n'
    import { useRouter, onBeforeRouteUpdate } from "vue-router";
    export default defineComponent({
        components: {
            ReviewingInformation,
            JudgingGuidelines,
        },
        setup() {
            const { t, locale } = useI18n()
            var store = useStore();
            const tabList = ref([
                { name: 'Judging Guidelines', ind: 1 },
                { name: 'Applicant Information', ind: 2 }
            ])
            const router = useRouter();
            const query = router.currentRoute.value.query;
            const activeInd = query.index?ref(Number(query.index)):ref(1);
            const tabClick = (ind) => {
                activeInd.value = ind;
                store._state.data.app.yearType = ind;
            }
            return {
                t,
                tabList,
                activeInd,
                tabClick
            }
        }
    })
</script>
<style lang="scss" scoped>
    .body {
        background: #f6f6f6;
        width: 100%;
        font-family: PingFangSC-Regular, PingFang SC;

        .contentbody {
            position: relative;
            background: #fff;
            margin: 0 auto;
            min-height: calc(100vh - 359px);
            margin: 20px auto 50px;
            padding: 40px;

            .maintitle {
                display: flex;
                justify-content: center;
                height: 35px;
                align-items: center;
                margin: top 4px;
                >div {
                    padding: 0 48px;
                    font-size: 21px;
                    font-weight: 600;
                    color: #222222;
                    cursor: pointer;
                    height: 100%;
                }

                div:hover {
                    color: #E34C3E;
                }

                >div:nth-child(1) {
                    border-right: 1px solid #EEEEEE;
                }

                .active:hover {
                    color: #E34C3E;
                }

                .active {
                    color: #E34C3E;
                    position: relative;
                    // padding-bottom: 15px;
                }

                .active::after {
                    content: "";
                    width: 60px;
                    height: 5px;
                    position: absolute;
                    bottom: -10px;
                    background: #E34C3E;
                    border-radius: 4px;
                    left: 38%;
                }
            }
        }
    }

    @media screen and (min-width: 375px) and (max-width: 750px) {
        .body {
            .maintitle {
                >div {
                    padding: 0 15px;
                    font-size: 24px;
                }
            }
        }

    }
</style>